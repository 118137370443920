/**
* This is the base theme for Shopliftr
*
* This file is used to define font-family, color pallette, and logo styling
* 
* Loaded with style.scss in the angular.json file as the default theme for all environments
*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --smart-ads-demo-color-background: white;
    --smart-ads-demo-color-button-selected-background: rgba(#56b4be, 0.05);
    --smart-ads-demo-color-button-selected-border: #56b4be;
    --smart-ads-demo-color-button-border: #aad9de;
    --smart-ads-demo-color-toggle-button: #b72d3f;
    --smart-ads-demo-color-text-button: #7f7f7f;
    --smart-ads-demo-color-text-title: #7f7f7f;
    --smart-ads-demo-color-text-normal: #4c4c4c;
    --smart-ads-demo-color-text-accent: #b72d3f;
    --smart-ads-demo-color-text-menu-highlight: #aad9de;
    --smart-ads-demo-color-navbar-background: #646464;
    --smart-ads-demo-color-navbar-text: white;
    --smart-ads-demo-color-video-control-text: white;
    --smart-ads-demo-color-video-background: black;
    --smart-ads-demo-color-video-control-background: rgba(76, 76, 76, 0.50);
    --smart-ads-demo-font-family: Poppins;
}

.smart-ads-demo-logo {
    width: 150px;
    content: url("/assets/images/logo-white-shopliftr.svg")
}
